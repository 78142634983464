import { useContext } from "react";
import { AuthContext } from "./Auth";

export default function Welcome() {
    const Auth = useContext(AuthContext);

    return <div className="Welcome">
        <div className="title">Welcome to My App</div>
        <p>A user friendly app for chat with your friends with beautiful design</p>
        <button onClick={() => { Auth.setAuthState("PhoneNumber"); }}>Get Started</button>
    </div>
}