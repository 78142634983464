import { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import "./Message.css";
import { AuthContext, UserContext } from "../Auth/Auth";
import { toDoubleDigit } from "./Home";
import { Icon, Profile } from "./common";
import MenuItem from "../UI/MenuItem";
import { socket } from "../../App";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from "@mui/material";
import { ChatContext } from "./ChatContext";
import { useDispatch } from "react-redux";
import { handleCall, handleContextMenu, handleEditMessage, handleReplyToMessage } from "../Stores/UI";
import MessageContextMenu from "./MessageContextMenu";
import { EmojiConvertor } from "emoji-js";
import MessageText from "./MessageText";
import MessageSeen from "./Message/MessageSeen";
import MessageMedia from "./Message/MessageMedia";
import MessageProfileMenu from "./MessageProfileMenu";
import { showUserProfile } from "./Pages/UserProfile";
import MessageCall from "./Message/MessageCall";

function Message({ data, prevMsgFrom, nextMsgFrom }) {
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [text, setText] = useState(data.message)

    const emoji = new EmojiConvertor()

    const Auth = useContext(AuthContext);
    const User = useContext(UserContext);

    const MessageEl = useRef()
    const messageText = useRef()

    const dispatch = useDispatch()

    const isOutMessage = data.from._id == User._id ? true : false;
    const msgTime = new Date(data.date);

    console.log('Message Rerendered')

    useEffect(() => {
        // console.log('editedd ', data.message)
        // messageText.current.dangerouslySetInnerHTML = handleMessageText()
        // setText(handleMessageText())
        MessageEl.current.removeEventListener('contextmenu', messageMenu)
        MessageEl.current.addEventListener('contextmenu', messageMenu)
        if (document.body.clientWidth <= 480) {
            MessageEl.current.onclick = messageMenu
        }
    }, [data.message])

    const handleContextMenuClose = useCallback(() => {
        dispatch(handleContextMenu())
    }, [])

    const handleReply = useCallback(() => {
        console.log(data)
        dispatch(handleReplyToMessage(data)); handleContextMenuClose()
    }, [data])

    const handleCopy = useCallback(() => {
        copyTextToClipboard(data.message); handleContextMenuClose()
    }, [data.message])

    const handleEdit = useCallback(() => {
        console.log('edited data: ', data)
        dispatch(handleEditMessage(data)); handleContextMenuClose()
    }, [data])

    const handleDelete = useCallback(() => {
        setOpenDeleteModal(true); handleContextMenuClose()
    }, [data])

    const handleViewProfile = useCallback(() => {
        showUserProfile(data.from, dispatch)
        handleContextMenuClose()
    }, [data])

    const deleteMessage = () => {
        handleClose()
        socket.emit('DeleteMessage', { token: Auth.authJWT, message: data })
    }

    const handleClose = () => {
        setOpenDeleteModal(false)
    }

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            // messageText.current.select();
            return await navigator.clipboard.writeText(text);
        } else {
            // messageText.current.select();
            // return document.execCommand('copy');
        }
    }

    const messageMenu = useCallback((e) => {
        e.preventDefault()

        if (!(e.target.closest('.message-reply') || e.target.closest('.message-from-profile') || e.target.closest('.message-media'))) {
            const items = (
                <>
                    <MessageContextMenu
                        canReply={true}
                        canCopy={true}
                        canEdit={User._id === data.from._id}
                        canDelete={true}
                        onReply={handleReply}
                        onCopy={handleCopy}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                </>
            )
            dispatch(handleContextMenu({ items, e }))
        } else if (e.target.closest('.message-from-profile')) {
            const items = (
                <>
                    <MessageProfileMenu
                        user={data.from}
                        canCall={true}
                        onMessage={() => { }}
                        onCall={() => { dispatch(handleCall(data.from)) }}
                        onView={handleViewProfile}
                    />
                </>
            )
            dispatch(handleContextMenu({ items, e }))
        }
    }, [data])

    const goToRepliedMessage = (e) => {
        try {
            console.log(data.reply)
            const repliedMessage = document.getElementById(data.reply._id)

            repliedMessage.scrollIntoView({ block: 'center' })
            repliedMessage.classList.add('highlight')
            setTimeout(() => {
                repliedMessage.classList.remove('highlight')
            }, 3000);
        } catch (error) {
            console.log('error:', error)
        }
    }

    var isSameFromPrevMsg = prevMsgFrom === data.fromId
    var isSameFromNextMsg = nextMsgFrom === data.fromId

    return (
        <div className={`Message ${isOutMessage ? "Out" : "In"}`} id={data._id} ref={MessageEl} onDoubleClick={handleReply}>
            {!isOutMessage && (
                <div className={"message-from-profile" + (isSameFromNextMsg ? ' hidden' : '')}>
                    <Profile name={data.from.firstname} size={36} />
                </div>
            )}
            <div className="bubble">
                <div className="body">
                    {!isOutMessage && (!isSameFromPrevMsg && data.type !== 'media') && <div className="from">{data.from.firstname}</div>}
                    {data.reply !== 0 && <div className="message-reply" onClick={goToRepliedMessage}>
                        <div className="line"></div>
                        <div className="body">
                            <div className="title">{data.reply.from.firstname}</div>
                            <div className="subtitle"><MessageText data={data.reply} /></div>
                        </div>
                    </div>}
                    <div className="message-text" dir="auto">
                        {data.type === 'media' &&
                            <MessageMedia media={data.media} data={data} />
                        }
                        {data.type === 'call' &&
                            <MessageCall data={data} />
                        }
                        <MessageText data={data} isInChat="true" />
                        <div className="message-details">
                            {data.edited && <span>edited</span>}
                            <div className="message-time">{`${toDoubleDigit(
                                msgTime.getHours()
                            )}:${toDoubleDigit(msgTime.getMinutes())}`}</div>
                            {isOutMessage && (
                                <MessageSeen data={data} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={openDeleteModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: '10px' } }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Do you want to delete this message?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will delete it for everyone in this chat.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>CANCEL</Button>
                    <Button color="error" onClick={deleteMessage}>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default memo(Message)