import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Icon } from "../App/common"

const Menu = forwardRef(({ icon, children }, ref) => {
    const menu = useRef()
    const [isActive, setIsActive] = useState()

    useEffect(() => {
        menu.current.querySelector('.icon').style.zIndex = isActive ? 5 : null
        if (!isActive) return
        let w = menu.current.querySelector('.DropdownMenu').clientWidth
        let h = menu.current.querySelector('.DropdownMenu').clientHeight
        menu.current.querySelector('.DropdownMenu').classList.add('animate')
        setTimeout(() => {
            menu.current.querySelector('.DropdownMenu').classList.remove('animate')
        }, 5);
        menu.current.querySelector('.DropdownMenu').style.minWidth = 36 + 'px';
        menu.current.querySelector('.DropdownMenu').style.width = 36 + 'px';
        menu.current.querySelector('.DropdownMenu').style.height = 36 + 'px';
        setTimeout(() => {
            menu.current.querySelector('.DropdownMenu').style.width = w + 'px'
            menu.current.querySelector('.DropdownMenu').style.height = h + 'px'
        }, 5);
    }, [isActive])

    useImperativeHandle(ref, () => ({
        handleOpenMenu() {
            handleOpenMenu()
        },
        handleCloseMenu() {
            handleCloseMenu()
        }
    }))

    const handleOpenMenu = () => {
        setIsActive(!isActive)
    }

    const handleCloseMenu = () => {
        setIsActive(false)
    }

    return (
        <div className="Menu" ref={menu}>
            <Icon name={icon} onClick={handleOpenMenu} />
            {isActive ? children : null}
        </div>
    )
})

export default Menu