import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext, UserContext } from "../../Auth/Auth";
import { PageClose, PageHandle, PageHeader, SubPage } from "../Page";
import { Icon, Profile } from "../common";
import DropdownMenu from "../../UI/DropdownMenu";
import MenuItem from "../../UI/MenuItem";
import Transition from "../Transition";
import { ChatContext } from "../ChatContext";
import Menu from "../../UI/Menu";
import { showUserProfile } from "./UserProfile";
import ManageGroup from "./ManageGroup/Manage";
import ContentEditable from "../../common/WrappedContentEditable";
import { socket } from "../../../App";
import { viewChat } from "../ChatList";


export default function Search() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [input, setInput] = useState('')
    const [chats, setChats] = useState([])

    const Auth = useContext(AuthContext)

    const dispatch = useDispatch()

    const page = useRef()
    const inputRef = useRef()
    const placeholderRef = useRef()

    const ui = useSelector((state) => state.ui.value)

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 100);
    }, [])

    const changeInputHandler = useCallback(e => {
        var value = e.target ? e.target.value : e;

        setInput(value);

        if (value !== "") {
            if (value.length >= 3) {
                socket.emit('Search', { token: Auth.authJWT, query: value })
                socket.on('Search', (response) => {
                    if (response.ok) {
                        setChats(response.data.chats)
                    }
                })
            }

            placeholderRef.current
                .classList.add("hidden");
        } else {
            placeholderRef.current
                .classList.remove("hidden");
        }
    }, [input]);

    const handleMessageInput = useCallback(() => {
        // setMessageInputHandled(input)
    }, [input])

    return <>
        <div className={"Search" + (!isLoaded ? ' fadeThrough' : '') + (ui.subPage[0] ? ' pushUp' : '')} ref={page}>
            <PageHeader>
                <div><Icon name="arrow_back" className="backBtn" onClick={() => PageClose(dispatch)} /></div>
                <div className="Title"><span>Search</span></div>
                <div className="Meta"></div>
            </PageHeader>
            <div className="section">
                <div className="Item Input" ref={inputRef}>
                    <ContentEditable
                        dir="auto"
                        className="InputField"
                        html={input} // innerHTML of the editable div
                        disabled={false}       // use true to disable editing
                        onChange={changeInputHandler} // handle innerHTML change
                        onBlur={handleMessageInput}
                        tagName='span' // Use a custom HTML tag (uses a div by default)
                    />
                    <span className="placeholder" style={{ paddingLeft: 28 }} ref={placeholderRef}>Search</span>
                </div>
            </div>
            <div className="section">
                <div className="Tabs">
                    <div className="Tab active"><span>Chats</span></div>
                </div>
                <div className="Items">
                    {chats.length > 0 && Object.values(chats).map((item) => (
                        <div className="Item" onClick={() => { viewChat(item, dispatch); PageClose(dispatch) }}>
                            <Profile size={44} name={item?.firstname} />
                            <div className="UserDetails">
                                <div className="title">{item?.firstname}</div>
                                <div className="subtitle">{item?.members.length > 1 ? item?.members.length + ' members' : '1 member'}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    </>
}