import { createContext, memo, useCallback, useEffect, useState } from "react";
import ChatList from "./ChatList";
import { Icon, Profile } from "./common";
import './LeftColumn.css'
import './MiddleColumn.css'
import '../../MATheme.css'
import { useRef } from "react";
import { useContext } from "react";
import { AuthContext, UserContext } from "../Auth/Auth";
import Message from "./Message";
import { socket } from "../../App";
import DropdownMenu from "../UI/DropdownMenu";
import MenuItem from "../UI/MenuItem";
import Menu from "../UI/Menu";
import Transition from "./Transition";
import Page, { PageHandle } from "./Page";
import Settings from "./Settings";
import './Settings.css'
import { Skeleton, ThemeProvider, createTheme } from "@mui/material";
import MessagesLoading from "../UI/MessagesLoading";
import Messages from "./Messages";
import ChatContextProvider, { ChatContext } from "./ChatContext";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "../Stores/store";
import { handleMessageError, messageAdded, setMessages, updateMessageId, updateMessageMediaUploadProgress, updateMessageSeen, updateMessageText } from "../Stores/Messages";
import { handleCall, handleCloseCall, handleContextMenu, handleEditMessage, handlePage, handlePageClose, handleReplyToMessage, handleTopbarTitleChange, handleUserProfile, setActiveChat, updateActiveChatPermissions } from "../Stores/UI";
import { setChat, updateLastMessage } from "../Stores/Chats";
import { EmojiConvertor } from "emoji-js";
import EmojiData from '@emoji-mart/data/sets/14/apple.json'
import Picker from '@emoji-mart/react'
import { Emoji } from "emoji-mart";
import ContentEditable from "../common/WrappedContentEditable";
import MessageText from "./MessageText";
import { IKContext, IKUpload } from "imagekitio-react";
import NewGroup from "./Pages/NewGroup";
import ChatProfile from "./Pages/ChatProfile";
import UserProfile, { showUserProfile } from "./Pages/UserProfile";
import Search from "./Pages/Search";
import Call from "./Call/Call";
import CallMinimal from "./Call/CallMinimal";

export const urlEndpoint = 'https://ik.imagekit.io/b4acyrnt3';
export const publicKey = 'public_2eNXL57bxEZ/Rt0HN1o55o4WPD4=';

function Home() {
    const [messageInput, setMessageInput] = useState("");
    const [messageInputHandled, setMessageInputHandled] = useState("");
    const [contextMenu, setContextMenu] = useState();
    const [backgroundAngle, setBackgroundAngle] = useState(120);
    const [msgsScrollTop, setMsgsScrollTop] = useState(0);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [autoScroll, setAutoScroll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [messageUploading, setMessageUploading] = useState();
    const [isWindowFocused, setIsWindowFocused] = useState(true);
    const [callState, setCallState] = useState({});
    const [connectionState, setConnectionState] = useState('connected');

    const Auth = useContext(AuthContext);
    const User = useContext(UserContext);
    const Chat = useContext(ChatContext);

    const LeftColumn = useRef();
    const MiddleColumn = useRef();
    const MessagesRef = useRef();
    const BottomRef = useRef();
    const scrollToBottom = useRef();
    const background = useRef();
    const messageInputEl = useRef();
    const placeholderRef = useRef();
    const sendButton = useRef();
    const attachButton = useRef();
    const contextMenuDiv = useRef();
    const contextMenuBG = useRef();
    const inputRefTest = useRef();
    const ikUploadRefTest = useRef();
    const fabQuickChatMenu = useRef();
    const CallRef = useRef();
    const CallStream = useRef();
    const flashTitleInterval = useRef();

    const dispatch = useDispatch()

    const emoji = new EmojiConvertor()

    const messages = useSelector((state) => state.messages.value)
    const chats = useSelector((state) => state.chats.value)
    const ui = useSelector((state) => state.ui.value)

    const authenticator = async () => {
        try {
            const response = await fetch('https://myapp2.liara.run/auth');

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Request failed with status ${response.status}: ${errorText}`);
            }

            const data = await response.json();
            const { signature, expire, token } = data;
            return { signature, expire, token };
        } catch (error) {
            throw new Error(`Authentication request failed: ${error.message}`);
        }
    };

    const handleKeyDown = (event) => {
        setTimeout(() => {
            // changeMessageInputHandler(event);
            // handleMessageInput()
        }, 0);
        if (event.key === "Enter") {
            event.preventDefault();
            handleMessageInput()
            sendMessage();
        }
    };

    const onReceiveMessage = (message) => {
        if (!User._id || User._id === message.fromId && message.type !== 'call') return
        dispatch(updateLastMessage({ _id: message.chatId, message: message }))
        dispatch(messageAdded(message));
        if (message.chatId === ui.activeChat?._id && User._id !== message.fromId)
            socket.emit('UpdateMessageSeen', { token: Auth.authJWT, message })
    }

    useEffect(() => {
        socket.on('ReceiveMessage', onReceiveMessage)
        return () => {
            socket.off('ReceiveMessage', onReceiveMessage)
        }
    }, [User, ui.activeChat]) // onReceiveMessage

    const onIncomingCall = (call) => {
        if (!User._id || User._id === call.fromId) return
        const to = Object.values(chats).find((obj) => obj.to?._id === call.fromId).to
        flashTitle(`Incoming Call - ${to.firstname}`)
        dispatch(handleCall({ ...call, ...to, incoming: true }));
    }

    useEffect(() => {
        socket.on('IncomingCall', onIncomingCall)
        return () => {
            socket.off('IncomingCall', onIncomingCall)
        }
    }, [User, chats, isWindowFocused]) // onIncomingCall

    const handleUpdateMessageSeen = (to) => {
        dispatch(updateMessageSeen({ _id: to._id, chatId: to.chatId, fromId: to.userId }))
        if (to._id === chats[to.chatId].lastMessage._id) {
            if (chats[to.chatId].lastMessage.seen && chats[to.chatId].lastMessage.seen?.indexOf(to.userId) === -1)
                dispatch(updateLastMessage({ _id: to.chatId, message: { ...chats[to.chatId].lastMessage, seen: [...chats[to.chatId].lastMessage.seen, to.userId] } }))
        }
    }

    const flashTitle = (newTitle) => {
        console.log(isWindowFocused)
        if (isWindowFocused) return
        flashTitleInterval.current = setInterval(() => {
            document.title === 'My App'
                ? (document.title = newTitle)
                : (document.title = 'My App');
        }, 1000);
    }

    useEffect(() => {
        socket.on('UpdateMessageSeen', handleUpdateMessageSeen)
        return () => socket.off('UpdateMessageSeen', handleUpdateMessageSeen)
    }, [User, chats]) // UpdateMessageSeen

    const handleUpdateGroupPermissions = (response) => {
        if (response.data) {
            response = response.data
        }
        dispatch(setChat(response))
        if (response._id === ui.activeChat?._id)
            dispatch(updateActiveChatPermissions(response.permissions))
    }

    useEffect(() => {
        socket.on('UpdateGroupPermissions', handleUpdateGroupPermissions)
        return () => socket.off('UpdateGroupPermissions', handleUpdateGroupPermissions)
    }, [User, chats, ui.activeChat])

    useEffect(() => {
        const updateMessage = (data) => {
            if (data.data) {
                data = data.data
            }
            // if (User._id && User._id !== data.fromId) {
            // let message = messages[messages.indexOf(data)]
            // const updatedMessages = [...messages]
            // let message = updatedMessages.find(x => x._id === data._id);
            // message.message = data.message;
            // message.edited = true;
            dispatch(updateMessageText({ _id: data._id, chatId: data.chatId, message: data.message }))
            if (chats[data.chatId].lastMessage._id === data._id) {
                dispatch(updateLastMessage({ _id: data.chatId, message: { ...chats[data.chatId].lastMessage, message: data.message } }))
            } else {
            }
            // }
        }
        socket.on('UpdateMessage', updateMessage)
        return () => socket.off('UpdateMessage', updateMessage);
    }, [messages, chats]) // UpdateMessage

    useEffect(() => {
        const deleteMessage = (data) => {
            if (data.data) data = data.data
            if (messages[data.chatId]) {
                dispatch(setMessages({
                    chatId: data.chatId,
                    messages: messages[data.chatId].filter(x =>
                        x._id !== data._id
                    )
                }));
            }
            if (chats[data.chatId].lastMessage._id === data._id) {
                dispatch(updateLastMessage({
                    _id: data.chatId, message: {
                        chatId: 1,
                        date: Date.now() / 1000,
                        forwardId: null,
                        from: { id: 2, firstname: "" },
                        fromId: 2,
                        id: Date.now(),
                        message: "Loading...",
                        reply: 0,
                        seen: null,
                        type: "text",
                        messageType: "message",
                    }
                }))
            }
        }
        socket.on('DeleteMessage', deleteMessage)
        return () => socket.off('DeleteMessage', deleteMessage);
    }, [messages, chats]) // DeleteMessage

    const handleScrollToBottom = () => {
        MessagesRef.current.scroll({ left: 0, top: MessagesRef.current.scrollHeight, behavior: "smooth" })
    }

    useEffect(() => {
        if (ui.activeChat && messages[ui.activeChat?._id]?.length && isLoading) {
            MessagesRef.current.scroll({ left: 0, top: MessagesRef.current.scrollHeight, behavior: "instant" })
            setIsLoading(false)
        }
    }, [ui.activeChat, messages[ui.activeChat?._id]]) // Scroll to Bottom on Load

    useEffect(() => {
        if (messages[ui.activeChat?._id]?.length && autoScroll) {
            handleScrollToBottom()
        }
    }, [messages[ui.activeChat?._id]]) // Scroll to Bottom on ReceiveMessage and SendMessage

    useEffect(() => {
        if (!ui.contextMenu) return;
        let e = ui.contextMenu.e
        let clientX = e.clientX
        let clientY = e.clientY
        let w = contextMenuDiv.current.clientWidth
        let h = contextMenuDiv.current.clientHeight
        setTimeout(() => {

        }, 0);
        let originX = 'left'
        let originY = 'top'
        if (clientX > document.body.clientWidth - (w + 20)) {
            clientX -= w
            originX = 'right'
        }
        if (clientY > document.body.clientHeight - (h + 20)) {
            clientY -= h
            originY = 'bottom'
        }
        setTimeout(() => {
            contextMenuBG.current.classList.remove('animate')
            contextMenuDiv.current.classList.remove('animate')
            contextMenuDiv.current.style.height = 0;
            setTimeout(() => {
                contextMenuDiv.current.style = `height: ${h}px; top: ${clientY}px;left: ${clientX}px;`
            }, 10);
        }, 0);
    }, [ui.contextMenu]) // ContextMenu

    useEffect(() => {
        if (ui.replyToMessage || ui.editMessage) {
            setTimeout(() => {
                document.querySelector(".PreviewMessage").classList.remove("animate");
                setTimeout(() => {
                    scrollToBottom.current.classList.add('hidden')
                }, 300);
            }, 0);
        }
        if (ui.editMessage) {
            changeMessageInputHandler(ui.editMessage.message);
            setTimeout(() => {
                messageInputEl.current.firstElementChild.focus();
            }, 10);
        }
    }, [ui.replyToMessage, ui.editMessage]) // replyToMessage and editMessage transition

    const handleSendButtonAnimation = () => {
        sendButton.current.firstElementChild.style =
            "transition:all .15s ease-out;";
        sendButton.current.firstElementChild.style.marginRight = "-42px";
        setTimeout(() => {
            changeMessageInputHandler("");
            setTimeout(() => {
                sendButton.current.firstElementChild.style.cssText +=
                    "transition:transform .3s ease;transform:scale(.9)";
                setTimeout(() => {
                    sendButton.current.firstElementChild.style.marginRight = "4px";
                    setTimeout(() => {
                        sendButton.current.firstElementChild.style = "";
                    }, 40);
                }, 100);
            }, 75);
        }, 100);
        messageInputEl.current.children[0].focus();
    }

    const sendMessage = useCallback((isMedia) => {
        if (!messageInputHandled || messageInputHandled === "" || messageInputHandled === " ") {
            console.log('message is empty', messageInputHandled)
            return false;
        }
        if (ui.editMessage) {
            let message = messages[ui.editMessage.chatId][messages[ui.editMessage.chatId].indexOf(ui.editMessage)]
            // socket.emit('UpdateMessage', { token: Auth.authJWT, message: { _id: message._id, chatId: message.chatId, fromId: message.fromId, message: messageInputHandled } })
            socket.emit('UpdateMessage', { token: Auth.authJWT, message: { _id: ui.editMessage._id, chatId: ui.editMessage.chatId, fromId: ui.editMessage.fromId, message: messageInputHandled } })
            socket.on('UpdateMessage', (response) => {
                if (response.ok) {
                    dispatch(handleEditMessage())
                    dispatch(updateMessageText({ _id: ui.editMessage._id, chatId: ui.editMessage.chatId, message: messageInputHandled }))
                    changeMessageInputHandler("");
                    socket.off('UpdateMessage')
                }
            })
            return true;
        }
        const message = {
            chatId: ui.activeChat._id,
            date: Date.now(),
            forwardId: null,
            from: User,
            fromId: User._id,
            id: Date.now(),
            message: htmlDecode(messageInputHandled),
            reply: ui.replyToMessage ? ui.replyToMessage : 0,
            seen: null,
            type: "text",
            messageType: "message",
        };

        if (ui.activeChat._id == 0 && ui.activeChat.type == 'private')
            message.toId = ui.activeChat.toId

        dispatch(messageAdded(message));
        handleScrollToBottom()
        if (ui.replyToMessage) dispatch(handleReplyToMessage());

        handleSendButtonAnimation()

        socket.emit('SendMessage', { token: Auth.authJWT, message })
        socket.on('SendMessage', (response) => {
            if (response.ok) {
                // message._id = response.data;
                dispatch(updateMessageId({ messageId: message.id, chatId: message.chatId, _id: response.data }))
                dispatch(updateLastMessage({ _id: message.chatId, message: { ...message, _id: response.data, seen: [] } }))
                // dispatch(messageAdded((current) => [...current]));
                socket.off('SendMessage')
            } else {
                dispatch(handleMessageError({ messageId: message.id, chatId: message.chatId }))
            }
        })
    }, [messageInputHandled]);

    const changeMessageInputHandler = useCallback(e => {
        if (e === null) e = ''
        var value = e.target ? e.target.value : e;

        emoji.replace_mode = 'img';
        emoji.img_sets.apple.path = 'https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-apple/15.0.1/img/apple/64/'
        emoji.allow_native = true;
        emoji.include_title = true
        var output = emoji.replace_colons(value);
        emoji.colons_mode = true
        var input = emoji.replace_unified(value)
        input = input.replaceAll(/<img.*?title="(.*?)"(\/?)>/g, ":$1:")

        setMessageInputHandled(input)
        setMessageInput(output);

        if (value !== "") {
            placeholderRef.current
                .classList.add("hidden");

            if (sendButton.current.children[0].innerHTML !== "send") {
                sendButton.current.children[0].classList.add("animate");
                attachButton.current.querySelector('.icon').classList.add("hidden");
                setTimeout(() => {
                    attachButton.current.style.display = "none";
                }, 300);
                setTimeout(() => {
                    sendButton.current.children[0].innerHTML = "send";
                    sendButton.current.children[0].classList.remove("animate");
                }, 150);
            }
        } else {
            placeholderRef.current
                .classList.remove("hidden");
            sendButton.current.children[0].classList.add("animate");
            attachButton.current.style = "";
            setTimeout(() => {
                attachButton.current.querySelector('.icon').classList.remove("hidden");
            }, 0);
            setTimeout(() => {
                sendButton.current.children[0].innerHTML = "mic";
                sendButton.current.children[0].classList.remove("animate");
            }, 150);
        }
    }, [messageInput]);

    const handleMessageInput = useCallback(() => {
        emoji.colons_mode = true
        var input = emoji.replace_unified(messageInput)
        input = input.replaceAll(/<img.*?title="(.*?)"(\/?)>/g, ":$1:")
        setMessageInputHandled(input)
    }, [messageInput])

    const htmlDecode = (input) => {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    const onGetMessages = (response) => {
        if (response.ok) {
            socket.emit('UpdateMessageSeen', { token: Auth.authJWT, message: response.data[response.data.length - 1] })
            dispatch(setMessages({ chatId: ui.activeChat._id, messages: response.data }))
        }
    }

    useEffect(() => {
        if (ui.activeChat) {
            dispatch(setMessages({ chatId: ui.activeChat._id, messages: null }))
            setIsLoading(true)
            socket.emit('GetMessages', { token: Auth.authJWT, chatId: ui.activeChat._id })
            socket.on('GetMessages', onGetMessages)

            scrollToBottom.current.style.bottom = BottomRef.current.clientHeight + 8 + 'px'

            return () => socket.off('GetMessages', onGetMessages)
        }
    }, [ui.activeChat]) // Get Messages on activeChat Changed

    const onScrollMessages = () => {
        if (Math.abs(MessagesRef.current.scrollHeight - MessagesRef.current.clientHeight - MessagesRef.current.scrollTop) > 30
            || msgsScrollTop > MessagesRef.current.scrollTop) {
            scrollToBottom.current.classList.remove('hidden')
            scrollToBottom.current.style.bottom = BottomRef.current.clientHeight + 8 + 'px'
            setAutoScroll(false)
        } else if (!scrollToBottom.current.classList.contains('hidden')) {
            scrollToBottom.current.classList.add('hidden')
            setAutoScroll(true)
        }
    }

    const onJoinGroup = () => {
        dispatch(setChat(ui.activeChat))
    }

    const handleJoinGroup = useCallback(() => {
        socket.emit('JoinGroup', { token: Auth.authJWT, chatId: ui.activeChat._id })
        socket.on('JoinGroup', onJoinGroup)
        return () => socket.off('JoinGroup', onJoinGroup);
    }, [User, ui.activeChat])

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            background: {
                paper: '#0e1214'
            }
        },
    });

    const showSettings = useCallback(() => {
        PageHandle(dispatch, 'Settings', 'Settings')
    }, [ui.page, ui.showPage])

    const showNewGroup = useCallback(() => {
        fabQuickChatMenu.current.handleCloseMenu()
        PageHandle(dispatch, 'NewGroup', 'New Group')
    }, [ui.page, ui.showPage])

    const showChatProfile = useCallback((e) => {
        if (e.target.closest('.BackArrow'))
            return
        if (ui.activeChat.type === 'private') {
            showUserProfile(ui.activeChat.to, dispatch)
        } else
            PageHandle(dispatch, 'ChatProfile', '')
    }, [ui.page, ui.showPage, ui.activeChat])

    const pageClose = useCallback(() => {
        document.querySelector('.TopBar .Title').style.animation = 'changeTitle .5s ease-in-out'
        dispatch(handlePageClose())
        setTimeout(() => {
            dispatch(handleTopbarTitleChange())
        }, 250);
        setTimeout(() => {
            document.querySelector('.TopBar .Title').style = ''
        }, 500);
    }, [])

    useEffect(() => {
        if (!ui.showPage)
            document.querySelector('.Menu .icon').style.animation = '0.3s ease-out 0s 1 normal none running backToMenu'
    }, [ui.showPage]) // Page Transition

    useEffect(() => {
        window.addEventListener('focus', () => {
            setIsWindowFocused(true)
            console.log('sob bekeheyr')
        })
        window.addEventListener('blur', () => {
            setIsWindowFocused(false)
            console.log('shab bekheyr')
        })

        socket.on("connect", () => {
            setConnectionState('connected')
        });

        socket.on("connect_error", () => {
            setConnectionState('Connecting...')
        });


        socket.on("disconnect", () => {
            setConnectionState('Waiting for network...')
        })

        socket.io.on("reconnect_attempt", () => {
            setConnectionState('Connecting...')
        });
    }, [])

    useEffect(() => {
        if (isWindowFocused && flashTitleInterval.current) {
            clearInterval(flashTitleInterval.current)
            document.title = 'My App'
        }
    }, [isWindowFocused])

    const chatInfoSubtitle = () => {
        switch (ui.activeChat.type) {
            case 'private':
                return 'Online'
            case 'group':
            default:
                return ui.activeChat.members.length > 1 ? ui.activeChat.members.length + ' members' : '1 member'
        }
    }

    const onUploadMedia = (e) => {
        console.log(e)
        const message = {
            chatId: ui.activeChat._id,
            date: Date.now(),
            forwardId: null,
            from: User,
            fromId: User._id,
            id: Date.now(),
            media: e.target.files,
            message: htmlDecode(messageInputHandled),
            reply: ui.replyToMessage ? ui.replyToMessage : 0,
            seen: null,
            isUploading: true,
            type: "media",
            messageType: "message",
        };

        ikUploadRefTest.current.message = message
        dispatch(messageAdded(message));
        handleScrollToBottom()
    }

    const onUploadMediaProgress = (e) => {
        var message = ikUploadRefTest.current.message;
        message.media[0].progress = { loaded: e.loaded, total: e.total }

        dispatch(updateMessageMediaUploadProgress(message));
    }

    const onUploadMediaSuccess = (e) => {
        var message = ikUploadRefTest.current.message

        message = { ...message, media: [e], isUploading: undefined }

        handleSendButtonAnimation()

        socket.emit('SendMessage', { token: Auth.authJWT, message })
        socket.on('SendMessage', (response) => {
            if (response.ok) {
                dispatch(updateMessageId({ messageId: message.id, chatId: message.chatId, _id: response.data }))
                dispatch(updateLastMessage({ _id: message.chatId, message: { ...message, _id: response.data, seen: [] } }))

                socket.off('SendMessage')
            }
        })
    }

    const getPageLayout = useCallback(() => {
        switch (ui.page) {
            case 'Settings':
                return <Settings />
            case 'Search':
                return <Search />
            case 'NewGroup':
                return <NewGroup />
            case 'ChatProfile':
                return <ChatProfile />
            case 'UserProfile':
                return <UserProfile />
            default:
                break;
        }
    }, [ui.page])

    return (
        <ThemeProvider theme={darkTheme}>
            <IKContext publicKey={publicKey} urlEndpoint={urlEndpoint} authenticator={authenticator}>
                <div className="Home">
                    <div className={"LeftColumn" + (ui.callLeftPanelClose ? ' Compact' : '')}>
                        <div className="TopBar">
                            <div>
                                {(ui.pageHeader && ui.showPage) ? <>{ui.pageHeader}</> : <>
                                    <Menu icon="menu">
                                        <DropdownMenu>
                                            <div className="top">
                                                <span></span>
                                                <Icon name="dark_mode" size={24} />
                                            </div>
                                            <MenuItem icon="person" title="Contacts" onClick={() => { }} />
                                            <MenuItem icon="call" title="Calls" onClick={() => { }} />
                                            <MenuItem icon="settings" title="Settings" onClick={showSettings} />
                                            <hr />
                                            <MenuItem profile={User} title={User.firstname + ' ' + User.lastname} onClick={() => { }} />
                                        </DropdownMenu>
                                    </Menu>
                                    <div className="Title"><span>{!socket.connected ? 'Connecting...' : (ui.topbarTitle ?? 'My App')}</span></div>
                                    <div className="Meta" onClick={() => PageHandle(dispatch, 'Search', 'Search')}><Icon name="search" /></div>
                                </>}
                            </div>
                        </div>
                        <Transition state={ui.showPage}><Page>{getPageLayout()}</Page></Transition>
                        <div className="Chats scrollable">
                            <ChatList />
                        </div>
                        {(!ui.showPage && !ui.callMinimal) && <div className="fab quickChatButton">
                            <Menu icon="edit" ref={fabQuickChatMenu}>
                                <DropdownMenu>
                                    <MenuItem icon="group_add" title="New Group" onClick={showNewGroup} />
                                    <MenuItem icon="add" title="New Channel" onClick={() => { }} />
                                    <MenuItem icon="person" title="Contacts" onClick={() => { }} />
                                    <hr />
                                    <MenuItem style={{ height: 46 }} title="QUICK CHAT" onClick={() => { }} />
                                </DropdownMenu>
                            </Menu>
                        </div>}
                        <Transition state={ui.callMinimal && ui.showCall}>
                            <CallMinimal Call={CallRef} CallStream={CallStream} CallState={callState} />
                        </Transition>
                    </div>
                    <Transition state={ui.showCall} action={() => dispatch(handleCall())}>
                        <Call ref={CallRef} CallStream={CallStream} setCallState={setCallState} />
                    </Transition>
                    <div className={`MiddleColumn ${ui.activeChat ? 'active' + (!ui.page ? ' focused' : '') : ''} ${(ui.showCall && !ui.callMinimal) ? 'C' : ''} ${ui.callLeftPanelClose ? 'L' : ''} ${ui.callMaximized ? 'X' : ''}`}>
                        <div className="background green"></div>
                        {ui.activeChat && <div className="Content">
                            <div className="TopBar">
                                <div className="ChatInfo" onClick={showChatProfile}>
                                    <div className="info">
                                        <Icon name="arrow_back" className="BackArrow" onClick={() => dispatch(setActiveChat())} />
                                        <div className="meta"><Profile name={ui.activeChat.firstname} /></div>
                                        <div className="body">
                                            <div className="title">{ui.activeChat.firstname}</div>
                                            <div className="subtitle">{chatInfoSubtitle()}</div>
                                        </div>
                                    </div>
                                    <div className="actions">
                                        <div className="Menu">
                                            <Icon name="more_vert" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Messages scrollable" ref={MessagesRef} onScroll={onScrollMessages}>
                                {messages[ui.activeChat._id] ?
                                    <Messages /> :
                                    (<div className="loading">
                                        {<MessagesLoading />}
                                    </div>)}
                            </div>
                            <div ref={scrollToBottom} className="scrollToBottom hidden" onClick={handleScrollToBottom}>
                                <Icon name="arrow_downward" />
                            </div>
                            <div className="bottom" ref={BottomRef}>
                                {ui.replyToMessage && (
                                    <div className="PreviewMessage animate">
                                        <Icon name="reply" className="meta" />
                                        <div className="body">
                                            <div className="title">Reply message</div>
                                            <div className="subtitle">
                                                <MessageText data={ui.replyToMessage} includeFrom />
                                            </div>
                                        </div>
                                        <div
                                            className="close icon"
                                            onClick={() => dispatch(handleReplyToMessage())}
                                        >
                                            close
                                        </div>
                                    </div>
                                )}
                                {ui.editMessage && (
                                    <div className="PreviewMessage animate">
                                        <Icon name="edit" className="meta" />
                                        <div className="body">
                                            <div className="title">Edit message</div>
                                            <div className="subtitle"><MessageText data={ui.editMessage} /></div>
                                        </div>
                                        <div
                                            className="close icon"
                                            onClick={() => {
                                                dispatch(handleEditMessage())
                                                changeMessageInputHandler("");
                                            }}
                                        >
                                            close
                                        </div>
                                    </div>
                                )}
                                {Object.values(chats).findIndex(item => item._id === ui.activeChat._id) === -1 && ui.activeChat.type !== 'private' ? <>
                                    <div className="Button" onClick={handleJoinGroup}>Join Group</div>
                                </> : <>
                                    <div className="Composer">
                                        <div className="emoji-button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                            <div className="icon">mood</div>
                                        </div>
                                        {showEmojiPicker && <div style={{ position: 'absolute', bottom: 50 }}>
                                            <Picker onEmojiSelect={(e) => { changeMessageInputHandler(messageInput + e.shortcodes) }} theme="dark" set="apple" previewPosition="none" data={EmojiData} />
                                        </div>}
                                        <div className="message-input" ref={messageInputEl}>
                                            {ui.activeChat.permissions.sendText ? <>
                                                <ContentEditable
                                                    dir="auto"
                                                    className="input"
                                                    html={messageInput} // innerHTML of the editable div
                                                    disabled={false}       // use true to disable editing
                                                    onChange={changeMessageInputHandler} // handle innerHTML change
                                                    onBlur={handleMessageInput}
                                                    onKeyDown={handleKeyDown}
                                                    tagName='span' // Use a custom HTML tag (uses a div by default)
                                                />
                                                <span className="placeholder" ref={placeholderRef}>Message</span>
                                            </> : <><Icon name="lock" /><span className="disabled">Text not allowed</span></>}
                                            <div className="attach-button" ref={attachButton}>
                                                <IKUpload
                                                    isPrivateFile={false}
                                                    useUniqueFileName={true}
                                                    validateFile={file => file.size < 20000000}
                                                    overwriteFile={true}
                                                    onError={console.log}
                                                    onSuccess={onUploadMediaSuccess}
                                                    onUploadProgress={onUploadMediaProgress}
                                                    onUploadStart={onUploadMedia}
                                                    // style={{display: 'none'}} // hide the default input and use the custom upload button
                                                    ref={ikUploadRefTest}
                                                />
                                                <Icon name="attachment" size="28" />
                                            </div>
                                        </div>
                                        <div
                                            className="send-button"
                                            ref={sendButton}
                                            onClick={() => {
                                                sendMessage();
                                            }}
                                        >
                                            <span className="icon">mic</span>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>}
                    </div>
                    {
                        ui.contextMenu && (<>
                            <div className="bg ContextMenuBG animate" ref={contextMenuBG} onClick={() => dispatch(handleContextMenu())}></div>
                            <div className="ContextMenu animate" ref={contextMenuDiv}>
                                {ui.contextMenu.items}
                            </div>
                        </>
                        )
                    }
                </div>
            </IKContext>
        </ThemeProvider>
    )
}

export default memo(Home)

export const toDoubleDigit = (number) => {
    return number.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
};