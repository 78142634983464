import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext, UserContext } from "../../../Auth/Auth";
import { ChatContext } from "../../ChatContext";
import { PageClose, PageHandle, PageHeader, SubPage } from "../../Page";
import { Icon, Profile, Switch } from "../../common";
import { showUserProfile } from "../UserProfile";
import Transition from "../../Transition";
import { setChat } from "../../../Stores/Chats";
import { setActiveChat } from "../../../Stores/UI";
import { socket } from "../../../../App";
import AdminRights from "./AdminRights";

export default function Members() {
    const dispatch = useDispatch()
    const User = useContext(UserContext)
    const Auth = useContext(AuthContext)

    const ui = useSelector((state) => state.ui.value)

    const [isLoaded, setIsLoaded] = useState(false)
    const [hasChanged, setHasChanged] = useState(false)
    const [sendText, setSendText] = useState(ui.activeChat.permissions.sendText)
    const [sendMedia, setSendMedia] = useState(ui.activeChat.permissions.sendMedia)
    const [addUsers, setAddUsers] = useState(ui.activeChat.permissions.addUsers)
    const [pinMessages, setPinMessages] = useState(ui.activeChat.permissions.pinMessages)

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 100);
    }, [])

    const getSubPageLayout = useCallback(() => {
        switch (ui.subPage[3]?.page) {
            case 'AdminRights':
                return <AdminRights />
            default:
                break;
        }
    }, [ui.subPage])

    const showAdminRights = (user, dispatch) => {
        // dispatch(handle(user))
        PageHandle(dispatch, 'AdminRights', 'Admin Rights', true, user)
    }

    return <>
        <div className={"Members" + (!isLoaded ? ' fadeThrough' : '') + (ui.subPage[3] ? ' pushUp' : '')}>
            <PageHeader key={ui.subPage[2]}>
                <div><Icon name="arrow_back" className="backBtn" onClick={() => PageClose(dispatch, true)} /></div>
                <div className="Title"><span>Members</span></div>
                <div className="Meta"></div>
            </PageHeader>
            <div className="section">
                <div className="Items">
                    {Object.values(ui.activeChat.members).filter(member => { return member._id !== User._id }).map((item) => (
                        <div className="Item" onClick={() => showAdminRights(item, dispatch)}>
                            <Profile size={44} name={item.firstname} />
                            <div className="UserDetails">
                                <div className="title">{item.firstname}</div>
                                <div className="subtitle">Online</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <Transition state={ui.subPage[3]}><SubPage>{getSubPageLayout()}</SubPage></Transition>
    </>
}