import { memo, useContext } from "react"
import { UserContext } from "../../Auth/Auth"

function MessageSeen({ data }) {
    const User = useContext(UserContext)
    return <div className={"message-seen icon" + (data.seen === -1 ? ' danger' : '')}>
        {data.seen?.length > 0 && (data.seen?.length > 1 || data.seen[0] !== User._id)
            ? "done_all"
            : (data.seen?.length === 0 || data.seen === 0 || (data.seen?.length === 1 && data.seen[0] === User._id))
                ? "done"
                : (data.seen === -1)
                    ? 'error'
                    : "schedule"}
    </div>
}

export default memo(MessageSeen)