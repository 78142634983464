import { useContext, useRef, useState } from "react";
import { AuthContext } from "./Auth";
import { socket } from "../../App";

export default function Register() {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");

    const firstnameTextField = useRef();

    const Auth = useContext(AuthContext);

    const registerUserHandler = () => {
        if (firstname !== "" && firstname !== null) {
            socket.emit('RegisterUser', {
                phone: Auth.authPhoneNumber,
                countryCode: Auth.authCountryCode,
                verify: btoa(Auth.authPassword),
                firstname: firstname,
                lastname: lastname
            }, { device: 'Edge 118' })

            socket.on('RegisterUser', (response) => {
                console.log(response)
                if (response.ok) {
                    localStorage.setItem("auth_key", response.data);
                    localStorage.setItem("authState", "Authorized");
                    Auth.setAuthKey(response.data);
                    Auth.setAuthState('Authorized')
                } else {
                    firstnameTextField.current.querySelector("label").innerHTML =
                        "Something went wrong";
                    firstnameTextField.current.classList.add("error");
                }
            })
        } else {
            firstnameTextField.current.querySelector("label").innerHTML =
                "This field is required";
            firstnameTextField.current.classList.add("error");
        }
    };

    return (
        <div className="Register">
            <div className="title">Choose a Name</div>
            <p className="subtitle">
                Choose a name for your account to identify you
            </p>
            <div className="textfield" ref={firstnameTextField}>
                <input
                    placeholder=" "
                    type="text"
                    value={firstname}
                    onChange={(e) => {
                        firstnameTextField.current.querySelector("label").innerHTML =
                            "First name";
                        firstnameTextField.current.classList.remove("error");
                        setFirstname(e.target.value);
                    }}
                />
                <label>First name</label>
            </div>
            <div className="textfield">
                <input
                    placeholder=" "
                    type="text"
                    value={lastname}
                    onChange={(e) => {
                        setLastname(e.target.value);
                    }}
                />
                <label>Last name (optional)</label>
            </div>
            <button onClick={() => { registerUserHandler(); }}>Next</button>
        </div>
    );
}
