import { createSlice } from '@reduxjs/toolkit'

export const chatsSlice = createSlice({
    name: 'chats',
    initialState: {
        value: {},
    },
    reducers: {
        chatAdded: (state, action) => {
            state.value.unshift(action.payload)
        },
        setChats: (state, action) => {
            state.value = {}
            action.payload.forEach(item => {
                if (item.type === 'private' && !item.permissions)
                    item.permissions =
                    {
                        sendText: true,
                        sendMedia: true,
                        pinMessages: true
                    }
                state.value[item._id] = item
            })
        },
        setChat: (state, action) => {
            if (state.value[action.payload._id]) {
                Object.keys(action.payload).map(x => {
                    state.value[action.payload._id][x] = action.payload[x]
                })
            } else {
                state.value[action.payload._id] = action.payload
            }
        },
        removeChat: (state, action) => {
            if (state.value[action.payload]) {
                Object.values(state.value).filter(x =>
                    x._id !== state.payload
                )
            }
        },
        updateLastMessage: (state, action) => {
            const chat = state.value[action.payload._id]
            if (chat) {
                chat.lastMessage = action.payload.message
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { chatAdded, setChats, setChat, removeChat, updateLastMessage } = chatsSlice.actions

export default chatsSlice.reducer