import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Icon, Profile } from "./common";
import { UserContext } from "../Auth/Auth";
import Page, { PageClose, PageHandle, PageHeader, SubPage, pageClose } from "./Page";
import DropdownMenu from "../UI/DropdownMenu";
import { handlePageClose, handleTopbarTitleChange } from "../Stores/UI";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "../UI/MenuItem";
import Menu from "../UI/Menu";
import SettingsGeneral from "./Pages/Settings/General";
import Transition from "./Transition";
import SettingsPrivacy from "./Pages/Settings/Privacy";

export default function Settings() {
    const [isLoaded, setIsLoaded] = useState(false)

    const dispatch = useDispatch()
    const User = useContext(UserContext)

    const page = useRef()

    const ui = useSelector((state) => state.ui.value)

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 100);
    }, [])

    const getSubPageLayout = useCallback(() => {
        switch (ui.subPage[0]?.page) {
            case 'General':
                return <SettingsGeneral />
            case 'Privacy':
                return <SettingsPrivacy />
            default:
                break;
        }
    }, [ui.subPage])

    return <>
        <div className={"Settings" + (!isLoaded ? ' fadeThrough' : '') + (ui.subPage[0] ? ' pushUp' : '')} ref={page}>
            <PageHeader>
                <div><Icon name="arrow_back" className="backBtn" onClick={() => PageClose(dispatch)} /></div>
                <div className="Title"><span>Settings</span></div>
                <div className="Meta">
                    <Menu icon="more_vert">
                        <DropdownMenu className="top right withoutTitle">
                            <MenuItem icon="edit" title="Edit Name" onClick={() => { }} />
                            <MenuItem icon="logout" className="danger" title="Log Out" onClick={() => { }} />
                        </DropdownMenu>
                    </Menu>
                </div>
            </PageHeader>
            <div className="section Info">
                <div className="User">
                    <Profile name={User.firstname} />
                    <div className="name">{User.firstname} {User.lastname}</div>
                </div>
                <div className="Items">
                    <div className="Item"><Icon name="phone" /><span>+{User.countryCode} {User.phone}</span></div>
                    <div className="Item"><Icon name="alternate_email" /><span>{User.username}</span></div>
                    <div className="Item"><Icon name="info" /><span>{User.bio}</span></div>
                </div>
            </div>
            <div className="section SettingsItems">
                <div className="Items">
                    <div className="Item" onClick={() => PageHandle(dispatch, 'General', 'General', true)}><Icon name="settings" /><span>General</span></div>
                    <div className="Item" onClick={() => PageHandle(dispatch, 'Privacy', 'Privacy and Security', true)}><Icon name="lock" /><span>Privacy and Security</span></div>
                    <div className="Item"><Icon name="database" /><span>Storage</span></div>
                    <div className="Item"><Icon name="chat" /><span>Chat Settings</span></div>
                    <div className="Item"><Icon name="devices" /><span>Devices</span></div>
                </div>
            </div>
            <div className="AppVersion">My App Web v0.3.0</div>
        </div>
        <Transition state={ui.subPage[0]}><SubPage>{getSubPageLayout()}</SubPage></Transition>
    </>
}