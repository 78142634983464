import Welcome from "./Welcome";
import './Auth.css';
import { createContext, useEffect, useState } from "react";
import PhoneNumber from "./PhoneNumber";
import Verify from "./Verify";
import Register from "./Register";
import Password from "./Password";
import Home from "../App/Home";
import { socket } from "../../App";
import ChatContextProvider from "../App/ChatContext";
import { Provider } from "react-redux";
import store from "../Stores/store";

export const AuthContext = createContext();
export const UserContext = createContext();

function getScreen({ authState }) {
    switch (authState) {
        case "Welcome":
            return <Welcome />;
        case "PhoneNumber":
            return <PhoneNumber />;
        case "Verify":
            return <Verify />;
        case "Password":
            return <Password />;
        case "Register":
            return <Register />;
        default:
            break;
    }
}

export function Auth() {
    const [user, setUser] = useState({ id: 1, firstname: "Test Man" });
    const [authState, setAuthState] = useState("");
    const [authKey, setAuthKey] = useState("");
    const [authPhoneNumber, setAuthPhoneNumber] = useState("");
    const [authCountryCode, setAuthCountryCode] = useState("");
    const [authPassword, setAuthPassword] = useState("");
    const [authJWT, setAuthJWT] = useState("");

    useEffect(() => {
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            //   document.body.classList.add("Dark");
        }

        if (
            localStorage.getItem("authState") === "Authorized" &&
            localStorage.getItem("auth_key") != ""
        ) {
            VerifySession()
        } else {
            // setAuthState("Authorized");
            setAuthState("Welcome");
            setTimeout(() => {
                // if (authState === "Welcome") {
                // container.current.classList.remove("hidden");
                // }
            }, 300);
        }
    }, []);

    useEffect(() => {
        if (!authJWT && authState === 'Authorized') VerifySession()
    }, [authState])

    const VerifySession = () => {
        socket.emit('VerifySession', { session: localStorage.getItem("auth_key") })
        socket.on('VerifySession', (response) => {
            console.log(response)
            if (response.ok) {
                setAuthJWT(response.data)
                setAuthKey(localStorage.getItem("auth_key"));
                setAuthState("Authorized");
                socket.emit('GetMe', { token: response.data })
                socket.on('GetMe', (data) => {
                    data.ok && setUser(data.data)
                })
            } else if (response.errorCode === 403) {
                console.log(response)
                setAuthState("Welcome")
            }
        })
        socket.on('RequestNewToken', () => {
            socket.emit('VerifySession', { session: localStorage.getItem("auth_key") })
        })
    }

    return <>
        <AuthContext.Provider
            value={{
                authState,
                setAuthState,
                authKey,
                setAuthKey,
                authPhoneNumber,
                setAuthPhoneNumber,
                authCountryCode,
                setAuthCountryCode,
                authPassword,
                setAuthPassword,
                authJWT
            }}
        >
            <UserContext.Provider value={user}>
                {authState === 'Authorized' ? <ChatContextProvider><Provider store={store}><Home /></Provider></ChatContextProvider> :
                    (<><div className="background"></div>
                        <div className="Auth container">
                            {getScreen({ authState })}
                        </div></>)
                }
            </UserContext.Provider>
        </AuthContext.Provider>
    </>
}