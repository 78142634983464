import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../Auth/Auth";
import { PageClose, PageHandle, PageHeader, SubPage } from "../Page";
import { Icon, Profile } from "../common";
import DropdownMenu from "../../UI/DropdownMenu";
import MenuItem from "../../UI/MenuItem";
import Transition from "../Transition";
import { ChatContext } from "../ChatContext";
import Menu from "../../UI/Menu";
import { handleCall, handlePage, handleTopbarTitleChange, handleUserProfile, setActiveChat } from "../../Stores/UI";


export default function UserProfile() {
    const [isLoaded, setIsLoaded] = useState(false)

    const dispatch = useDispatch()
    const User = useContext(UserContext)
    const Chat = useContext(ChatContext)

    const page = useRef()

    const ui = useSelector((state) => state.ui.value)

    useEffect(() => {
        if (ui.userProfile._id === User._id) {
            PageHandle(dispatch, 'Settings', 'Settings')
            return;
        } else
            setTimeout(() => {
                setIsLoaded(true)
            }, 100);
    }, [])

    const getSubPageLayout = useCallback(() => {
        switch (ui.subPage[0]?.page) {
            default:
                break;
        }
    }, [ui.subPage])

    const privateMessage = useCallback(() => {
        dispatch(setActiveChat({
            _id: 0,
            toId: ui.userProfile._id,
            firstname: ui.userProfile.firstname,
            to: ui.userProfile,
            permissions: {
                sendText: true,
                sendMedia: true,
                pinMessages: true
            },
            type: 'private'
        }))
    }, [])

    return <>
        <div className={"UserProfile" + (!isLoaded ? ' fadeThrough' : '') + (ui.subPage[0] ? ' pushUp' : '')} ref={page}>
            <PageHeader>
                <div><Icon name="arrow_back" className="backBtn" onClick={() => PageClose(dispatch)} /></div>
                <div className="Title"><span></span></div>
                <div className="Meta">
                    <Icon name="call" onClick={() => dispatch(handleCall(ui.userProfile))} />
                    <Menu icon="more_vert">
                        <DropdownMenu className="top right withoutTitle">
                            <MenuItem icon="edit" title="Edit Contact" onClick={() => { }} />
                            <MenuItem icon="delete" title="Delete Contact" onClick={() => { }} />
                            <MenuItem icon="block" title="Block User" className="danger" onClick={() => { }} />
                        </DropdownMenu>
                    </Menu>
                </div>
            </PageHeader>
            <div className="section Info">
                <div className="User">
                    <Profile name={ui.userProfile.firstname} />
                    <div className="name">{ui.userProfile.firstname} {ui.userProfile.lastname}</div>
                    <div className="StartChat" onClick={privateMessage}><Icon name="chat" /></div>
                </div>
                <div className="Items">
                    <div className="Item"><Icon name="phone" /><span>+{ui.userProfile.countryCode} {ui.userProfile.phone}</span></div>
                    <div className="Item"><Icon name="alternate_email" /><span>{ui.userProfile.username}</span></div>
                    <div className="Item"><Icon name="info" /><span>{ui.userProfile.bio}</span></div>
                </div>
            </div>
            <div className="section">
                <div className="Tabs">
                    <div className="Tab active"><span>Media</span></div>
                </div>
                <div className="Items">

                </div>
            </div>
        </div>
        {/* <Transition state={ui.subPage[0]}><SubPage>{getSubPageLayout()}</SubPage></Transition> */}
    </>
}

export const showUserProfile = (user, dispatch) => {
    dispatch(handleUserProfile(user))
    PageHandle(dispatch, 'UserProfile', '')
}