import { useContext, useRef, useState } from "react";
import { AuthContext } from "./Auth";
import { socket } from "../../App";

export default function Verify() {
    const [password, setPassword] = useState("");

    const passwordTextField = useRef();

    const Auth = useContext(AuthContext);

    const Verify = () => {
        socket.emit('AuthenticateUser', { phone: Auth.authPhoneNumber, countryCode: Auth.authCountryCode, verify: btoa(password) }, { device: 'Edge 118' })
        socket.on('AuthenticateUser', (response) => {
            console.log(response)
            if (response.ok) {
                Auth.setAuthState('Authorized')
                localStorage.setItem("auth_key", response.data);
                localStorage.setItem("authState", "Authorized");
            } else if (response.errorCode === 403) {
                passwordTextField.current.querySelector("label").innerHTML =
                    "Incorrect password";
                passwordTextField.current.classList.add("error");
            }
        })
    }

    return <div className="Verify">
        <div className="title">Enter Password</div>
        <p className="subtitle">
            Your account is protected with password, so enter the password to be
            verified
        </p>
        <div className="textfield" ref={passwordTextField}>
            <input
                placeholder=" "
                type="text"
                value={password}
                onChange={(e) => {
                    if (passwordTextField.current.classList.contains("error")) {
                        passwordTextField.current.querySelector("label").innerHTML =
                            "Password";
                        passwordTextField.current.classList.remove("error");
                    }
                    setPassword(e.target.value);
                }}
            />
            <label>Password</label>
        </div>
        <button onClick={() => { Verify() }}>Next</button>
    </div>
}