import { CircularProgress, circularProgressClasses } from "@mui/material";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { Icon } from "../common";
import { IKImage } from "imagekitio-react";
import { urlEndpoint } from "../Home";
import Transition from "../Transition";

function MessageMedia({ media, data }) {
    const [size, setSize] = useState(16)
    const [progress, setProgress] = useState()
    const [isLoaded, setIsLoaded] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const messages = useSelector((state) => state.messages.value)
    // const message = messages[data.chatId].indexOf(data._id)
    const image = useRef()
    const messageMedia = useRef()

    const resizeMedia = useCallback(() => {
        // var width = mediaDetails.width
        // var height = mediaDetails.height

        // var max_size

        // if (window.innerWidth >= 420) {
        //     max_size = 420
        // } else {
        //     max_size = window.innerWidth * .70
        // }

        // if (width > height) {
        //     if (width > max_size) {
        //         height *= max_size / width;
        //         width = max_size;
        //     }
        // } else {
        //     if (height > max_size) {
        //         width *= max_size / height;
        //         height = max_size;
        //     }
        // }

        // messageMedia.style.width = width + 'px'
        // messageMedia.style.height = height + 'px'
    }, [])

    useEffect(() => {
        if (data.progress) {
            console.log('progress data changed')
            setProgress(data.progress)
            if (data.progress.loaded === data.progress.total) {
                setIsLoaded(true)
            }
        }
    }, [data.progress])

    useEffect(() => {
        console.log('Media Rerendered')
        console.log(media)
        if (data.isUploading) {
            var fr = new FileReader();

            fr.readAsDataURL(media[0]);

            fr.onload = function (e) {
                image.current.src = this.result
            };
        } else {
            console.log('image is loading...')

        }
    }, [media])

    const downloadMedia = () => {
        if (isDownloading) {
            console.log('canceled')
            image.current.onAbort()
            setSize(16)
            setIsDownloading(false)
        } else {
            // messageMedia.current.querySelector('img').transformation = {}
            setSize()
            setIsDownloading(true)
            image.current.onDownload()
            console.log('Downloading...')
        }
    }

    const getMediaLayout = () => {
        console.log(media[0].fileType)
        switch (media[0].fileType) {
            case 'image':
                return <Image ref={image} path={media[0].filePath} size={size} width={media[0].width} height={media[0].height} isLoaded={isLoaded} setIsLoaded={setIsLoaded} setProgress={setProgress} setIsDownloading={setIsDownloading} />
            default:
                return <Document ref={image} path={media[0].filePath} isLoaded={isLoaded} setIsLoaded={setIsLoaded} setProgress={setProgress} />
        }
    }

    return <div className="message-media" ref={messageMedia}>
        {data.isUploading ?
            <img ref={image} /> :
            // <IKImage
            //     ref={image}
            //     className="blurred"
            //     urlEndpoint={urlEndpoint}
            //     path={media[0].filePath}
            //     transformation={[{
            //         height: size,
            //         width: size
            //     }]}
            //     onLoad={console.log('image loaded')}
            //     onProgress={console.log}
            // />}
            getMediaLayout()
        }
        <Transition state={!isLoaded}>
            <div className="message-loading-progress" onClick={downloadMedia}>
                <Icon name={(isDownloading || data.isUploading) ? "close" : "arrow_downward"} size={28} />
                {(isDownloading || data.isUploading) && <CircularProgress variant="determinate" style={{ color: '#fff', animation: 'spinner 3s linear infinite' }} sx={{ [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' } }} thickness={3} size={48} value={progress ? (progress.loaded / progress.total) * 100 : 1} />}
            </div>
        </Transition>

    </div>
}

export default memo(MessageMedia)

const Document = forwardRef(({ path, setProgress, isLoaded, setIsLoaded }, ref) => {
    const url = `${urlEndpoint}${path}`
    const file = useRef()
    const request = useRef()

    useImperativeHandle(ref, () => ({
        onAbort() {
            console.log('Document aborted')
            request.current.abort()
        },
        onDownload() {
            var xmlHTTP = new XMLHttpRequest();
            xmlHTTP.open('GET', url, true);
            xmlHTTP.responseType = 'arraybuffer';
            xmlHTTP.onload = function (e) {
                var blob = new Blob([this.response]);
                if (file.current)
                    file.current.src = window.URL.createObjectURL(blob);
                setIsLoaded(true)
            };
            xmlHTTP.onprogress = function (e) {
                setProgress({ loaded: e.loaded, total: e.total })
            };
            xmlHTTP.onloadstart = function () {
                setProgress(1)
            };
            xmlHTTP.send();
            request.current = xmlHTTP
        }
    }))

    return <div className="Document">

    </div>
})

const Image = forwardRef(({ path, size, width, height, setProgress, isLoaded, setIsLoaded, setIsDownloading }, ref) => {
    const url = `${urlEndpoint}${size ? `/tr:h-${size},w-${size}` : ''}${path}`
    const img = useRef()
    const isLowQualityLoaded = useRef(false)
    const request = useRef()

    const onClick = (e) => {
        const { x, y } = img.current.getBoundingClientRect()


    }

    useImperativeHandle(ref, () => ({
        onAbort() {
            console.log('Image aborted')
            request.current.abort()
        }
    }))

    const MOBILE_SCREEN_NO_AVATARS_MESSAGE_EXTRA_WIDTH_REM = 4.5;
    const MOBILE_SCREEN_MESSAGE_EXTRA_WIDTH_REM = 7;
    const MESSAGE_MAX_WIDTH_REM = 27;
    const MESSAGE_OWN_MAX_WIDTH_REM = 30;
    const REM = 16
    const isMobile = window.innerWidth < 480

    let cachedMaxWidthOwn;
    let cachedMaxWidth;
    let cachedMaxWidthNoAvatar;

    function getMaxMessageWidthRem() {
        const regularMaxWidth = MESSAGE_MAX_WIDTH_REM;
        if (!isMobile) {
            return regularMaxWidth;
        }

        const windowWidth = window.innerWidth

        // @optimization Limitation: changing device screen width not supported
        if (!cachedMaxWidthOwn) {
            cachedMaxWidthOwn = Math.min(
                MESSAGE_OWN_MAX_WIDTH_REM,
                windowWidth / REM - MOBILE_SCREEN_NO_AVATARS_MESSAGE_EXTRA_WIDTH_REM,
            );
        }
        if (!cachedMaxWidth) {
            cachedMaxWidth = Math.min(
                MESSAGE_MAX_WIDTH_REM,
                windowWidth / REM - MOBILE_SCREEN_MESSAGE_EXTRA_WIDTH_REM,
            );
        }
        if (!cachedMaxWidthNoAvatar) {
            cachedMaxWidthNoAvatar = Math.min(
                MESSAGE_MAX_WIDTH_REM,
                windowWidth / REM - MOBILE_SCREEN_NO_AVATARS_MESSAGE_EXTRA_WIDTH_REM,
            );
        }

        return cachedMaxWidth
    }

    const calculateMediaDimensions = () => {
        const aspectRatio = height / width;
        const availableWidthRem = getMaxMessageWidthRem() * 16;
        const calculatedWidth = Math.min(width, availableWidthRem);
        const availableHeight = 27 * 16;
        const calculatedHeight = Math.round(calculatedWidth * aspectRatio);

        if (calculatedHeight > availableHeight) {
            return {
                width: Math.round(availableHeight / aspectRatio),
                height: availableHeight,
            };
        }

        return {
            height: calculatedWidth,
            width: Math.round(calculatedWidth * aspectRatio),
        };
    }

    useEffect(() => {
        if (isLowQualityLoaded.current && size === 16)
            return
        var xmlHTTP = new XMLHttpRequest();
        xmlHTTP.open('GET', url, true);
        xmlHTTP.responseType = 'arraybuffer';
        xmlHTTP.onload = function (e) {
            var blob = new Blob([this.response]);
            if (img.current)
                img.current.src = window.URL.createObjectURL(blob);
            if (size !== 16)
                setIsLoaded(true)
            else
                isLowQualityLoaded.current = true
        };
        xmlHTTP.onprogress = function (e) {
            if (size === 16) return
            // console.log(parseInt((e.loaded / e.total) * 100))
            setProgress({ loaded: e.loaded, total: e.total })
        };
        xmlHTTP.onloadstart = function () {
            if (size === 16) return
            setProgress(1)
        };
        xmlHTTP.send();
        request.current = xmlHTTP
    }, [path, size])

    return <img ref={img} width={calculateMediaDimensions().width} height={calculateMediaDimensions().height} className={isLoaded ? '' : 'blurred'} />
})