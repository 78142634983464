import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext, UserContext } from "../../Auth/Auth";
import { PageClose, PageHandle, PageHeader, SubPage } from "../Page";
import { Icon, Profile } from "../common";
import DropdownMenu from "../../UI/DropdownMenu";
import MenuItem from "../../UI/MenuItem";
import Transition from "../Transition";
import { ChatContext } from "../ChatContext";
import Menu from "../../UI/Menu";
import { showUserProfile } from "./UserProfile";
import ManageGroup from "./ManageGroup/Manage";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { removeChat } from "../../Stores/Chats";
import { socket } from "../../../App";


export default function ChatProfile() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const dispatch = useDispatch()
    const Auth = useContext(AuthContext)

    const page = useRef()

    const ui = useSelector((state) => state.ui.value)

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 100);
    }, [])

    const onLeaveGroup = (response) => {
        if (response.ok) {
            dispatch(removeChat(ui.activeChat._id))
            setOpenModal(false)
        }
    }

    const leaveGroup = () => {
        socket.emit('LeaveGroup', { token: Auth.authJWT, chatId: ui.activeChat._id })
        socket.on('LeaveGroup', onLeaveGroup)
        return () => socket.off('LeaveGroup', onLeaveGroup);
    }

    const getSubPageLayout = useCallback(() => {
        switch (ui.subPage[0]?.page) {
            case 'Manage':
                return <ManageGroup />
            default:
                break;
        }
    }, [ui.subPage])

    return <>
        <div className={"ChatProfile" + (!isLoaded ? ' fadeThrough' : '') + (ui.subPage[0] ? ' pushUp' : '')} ref={page}>
            <PageHeader>
                <div><Icon name="arrow_back" className="backBtn" onClick={() => PageClose(dispatch)} /></div>
                <div className="Title"><span></span></div>
                <div className="Meta">
                    {ui.activeChat?.isAdmin && <button onClick={() => { PageHandle(dispatch, 'Manage', 'Manage', true) }}>Manage</button>}
                    <Menu icon="more_vert">
                        <DropdownMenu className="top right withoutTitle">
                            <MenuItem icon="logout" title="Leave Group" className="danger" onClick={() => setOpenModal(true)} />
                        </DropdownMenu>
                    </Menu>
                </div>
            </PageHeader>
            <div className="section Info">
                <div className="User">
                    <Profile name={ui.activeChat.firstname} />
                    <div className="name">{ui.activeChat.firstname}</div>
                </div>
                <div className="Items">
                    <div className="Item"><Icon name="alternate_email" /><span>{ui.activeChat.username}</span></div>
                    <div className="Item"><Icon name="info" /><span>{ui.activeChat.bio}</span></div>
                </div>
            </div>
            <div className="section">
                <div className="Tabs">
                    <div className="Tab active"><span>Members</span></div>
                </div>
                <div className="Items">
                    <div className="Item" onClick={() => { }}><Icon name="person_add" /><span>Add Members</span></div>
                    {Object.values(ui.activeChat.members).map((item) => (
                        <div className="Item" onClick={() => showUserProfile(item, dispatch)}>
                            <Profile size={44} name={item.firstname} />
                            <div className="UserDetails">
                                <div className="title">{item.firstname}</div>
                                <div className="subtitle">Online</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div >
        <Transition state={ui.subPage[0]}><SubPage>{getSubPageLayout()}</SubPage></Transition>
        <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { borderRadius: '10px' } }}
        >
            <DialogTitle id="alert-dialog-title" className="flex">
                <Profile name={ui.activeChat.firstname} />
                {"Leave Group"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to leave {ui.activeChat.firstname}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)}>CANCEL</Button>
                <Button color="error" onClick={leaveGroup}>
                    LEAVE GROUP
                </Button>
            </DialogActions>
        </Dialog>
    </>
}