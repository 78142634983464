import { useEffect, useRef, useState } from "react"

export default function Transition({ state, action, children }) {
    const [isActive, setIsActive] = useState(false)

    const element = useRef()

    useEffect(() => {
        if (state) {
            setIsActive(true)
        } else if (isActive) {
            element.current.firstElementChild.classList.add('animate')
            element.current.firstElementChild.classList.add('hideAnim')
            setTimeout(() => {
                setIsActive(false)
            }, 300);
        }
    }, [state])

    useEffect(() => {
        if (isActive) {
            element.current.firstElementChild.classList.add('animate')
            element.current.firstElementChild.classList.add('showAnim')
            setTimeout(() => {
                element.current.firstElementChild.classList.remove('animate')
                element.current.firstElementChild.classList.remove('showAnim')
            }, 40);
        } else {
            if (action)
                action()
        }
    }, [isActive])

    return <div className="Transition" ref={element}>
        {isActive ? children : null}
    </div>
}