import { createSlice } from '@reduxjs/toolkit'

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        value: {
            contextMenu: null,
            replyToMessage: null,
            editMessage: null,
            page: null,
            showPage: false,
            pageTitle: null,
            topbarTitle: null,
            pageHeader: null,
            subPage: [],
            subPageTitle: null,
            userProfile: null,
            activeChat: null,
            call: null,
            showCall: false,
            callLeftPanelClose: false,
            callMinimal: false,
            callMaximized: false,
        },
    },
    reducers: {
        handleContextMenu: (state, action) => {
            state.value.contextMenu = action.payload
        },
        handleReplyToMessage: (state, action) => {
            state.value.replyToMessage = action.payload
        },
        handleEditMessage: (state, action) => {
            state.value.editMessage = action.payload
        },
        handlePage: (state, action) => {
            state.value.page = action.payload
            state.value.subPage = []
            state.value.pageTitle = action.payload
            state.value.showPage = true
            state.value.callLeftPanelClose = false
        },
        handleTopbarTitleChange: (state, action) => {
            state.value.topbarTitle = action.payload
        },
        handlePageClose: (state) => {
            state.value.page = null
            state.value.subPage = []
            state.value.pageTitle = null
            state.value.showPage = false
        },
        handlePageHeader: (state, action) => {
            state.value.pageHeader = action.payload
        },
        handleSubPage: (state, action) => {
            state.value.subPage.push(action.payload)
            state.value.subPageTitle = action.payload
        },
        handleSubPageClose: (state) => {
            state.value.subPage.splice(state.value.subPage.length - 1, 1)
            state.value.subPageTitle = null
        },
        handleUserProfile: (state, action) => {
            state.value.userProfile = action.payload
        },
        setActiveChat: (state, action) => {
            state.value.activeChat = action.payload
        },
        updateActiveChatPermissions: (state, action) => {
            state.value.activeChat.permissions = action.payload
        },
        handleCall: (state, action) => {
            if (action.payload) {
                state.value.showCall = true
            }
            state.value.call = action.payload
        },
        handleCloseCall: (state, action) => {
            state.value.showCall = false
            state.value.callLeftPanelClose = false
        },
        handleCallLeftPanelToggle: (state) => {
            state.value.callLeftPanelClose = !state.value.callLeftPanelClose
            state.value.page = null
            state.value.subPage = []
            state.value.pageTitle = null
            state.value.topbarTitle = null
            state.value.showPage = false
        },
        handleCallMinimalToggle: (state) => {
            state.value.callMinimal = !state.value.callMinimal
            state.value.callLeftPanelClose = false
        },
        handleCallMaximizedToggle: (state) => {
            state.value.callMaximized = !state.value.callMaximized
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    handleContextMenu,
    handleReplyToMessage,
    handleEditMessage,
    handlePage,
    handlePageClose,
    handleTopbarTitleChange,
    handlePageHeader,
    handleSubPage,
    handleSubPageClose,
    handleUserProfile,
    setActiveChat,
    updateActiveChatPermissions,
    handleCall,
    handleCloseCall,
    handleCallLeftPanelToggle,
    handleCallMinimalToggle,
    handleCallMaximizedToggle
} = uiSlice.actions

export default uiSlice.reducer