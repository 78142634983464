import { useState, useContext, useCallback } from "react";
import { AuthContext } from "./Auth";
import { socket } from "../../App";

export default function PhoneNumber() {
    const [countryCode, setCountryCode] = useState("98");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [formatedPhoneNumber, setFormatedPhoneNumber] = useState("+98");

    const Auth = useContext(AuthContext);

    const phoneNumberHandler = (e) => {
        var value = e.target.value;

        var matches = value
            .replace(/[^+\d]/g, "")
            .match(/(\+?)(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/);

        var _format = "";

        for (let i = 1; i < matches.length; i++) {
            const match = matches[i];

            i === 1
                ? (_format = "+")
                : match !== "" && (_format = _format + (i > 2 ? " " : "") + match);
        }
        setFormatedPhoneNumber(_format);
        setPhoneNumber(
            value
                .replace(/\D+/g, "")
                .replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$2$3$4")
        );
        setCountryCode(
            value
                .replace(/\D+/g, "")
                .replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1"))
    }

    const Validate = () => {
        socket.emit('ValidatePhoneNumber', { phone: phoneNumber, countryCode: countryCode })
        socket.on('ValidatePhoneNumber', (response) => {
            console.log(response)
            if (response.ok) {
                Auth.setAuthPhoneNumber(phoneNumber)
                Auth.setAuthCountryCode(countryCode)
                Auth.setAuthState('Verify')
            } else if (response.errorCode === 404) {
                Auth.setAuthPhoneNumber(phoneNumber)
                Auth.setAuthCountryCode(countryCode)
                Auth.setAuthState('Password')
            }
        })
    }

    return <div className="PhoneNumber">
        <div>
            <div className="title">Sign in to My App</div>
            <p className="subtitle">
                Please confirm your country and enter your phone number
            </p>
            <div className="textfield">
                <input
                    placeholder=" "
                    type="text"
                    value={formatedPhoneNumber}
                    onChange={useCallback((e) => {
                        phoneNumberHandler(e);
                    })}
                />
                <label>Phone number</label>
            </div>
            <button onClick={() => { Validate() }}>Next</button>
        </div>
    </div>;
}