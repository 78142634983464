import './App.css';
import './assets/styles/Material Symbol Rounded.css';
import './Mobile.css';
import { Auth } from './Components/Auth/Auth';
import { io } from "socket.io-client";

export const socket = io("wss://myapp2.liara.run", { // wss://myapp2.liara.run
  reconnectionDelayMax: 10000,
});

function App() {
  return (
    <div className="App Dark">
      <div className="background"></div>
      <Auth />
    </div>
  );
}

export default App;
