import { memo, useContext, useEffect } from "react";
import Message from "./Message";
import { ChatContext } from "./ChatContext";
import { useDispatch, useSelector } from "react-redux";

function Messages() {
    const activeChat = useSelector((state) => state.ui.value.activeChat)

    const messages = useSelector((state) => state.messages.value)

    console.log('Messages Rerender')

    return <>
        {messages[activeChat._id].map((item, index) => (
            <Message key={index} data={item} prevMsgFrom={messages[activeChat._id][index - 1]?.fromId} nextMsgFrom={messages[activeChat._id][index + 1]?.fromId} />
        ))}
    </>
}

export default memo(Messages)