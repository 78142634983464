import { useCallback } from "react"

export function Icon({ name, size = 24, color = null, onClick, className = null }) {
    return <div className={"icon" + (className ? ' ' + className : '')} style={{ fontSize: size + 'px' }} onClick={onClick}>{name}</div>
}

export function Profile({ image, name, size = 48 }) {
    return <div className="profile" style={{ width: size + 'px', height: size + 'px', fontSize: size / 2 + 'px' }}>
        <span>{name.charAt(0).toUpperCase()}</span>
        {/* <img src="" /> */}
    </div>
}

export function Switch({ checked = false, setChecked }) {
    const onChange = useCallback(() => {
        if (setChecked)
            setChecked(!checked)
    }, [checked, setChecked])
    return <label className="switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider"></span>
    </label>
}