import { memo, useContext, useEffect, useState } from "react"
import { EmojiConvertor } from "emoji-js";
import { UserContext } from "../Auth/Auth";

function MessageText({ data, isInChat = false, includeFrom = false }) {
    const [text, setText] = useState(data.message)
    const emoji = new EmojiConvertor()

    const User = useContext(UserContext)

    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            if (data.message) {
                emoji.replace_mode = 'img';
                emoji.img_sets.apple.path = 'https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-apple/15.0.1/img/apple/64/'
                emoji.allow_native = true;
                emoji.include_title = true
                var output = emoji.replace_colons(data.message);
                output = (includeFrom ? data.from.firstname + ': ' : '') + data.message
                setText(output)
            } else if (!isInChat) {
                if (data.type === 'media') {
                    setText((includeFrom ? data.from.firstname + ': ' : '') + 'Media') // TODO : Set it to Photo, Video, etc
                } else if (data.type === 'call') {
                    var output = ''
                    switch (data.call?.status) {
                        case 'declined':
                            output = 'Declined Call'
                            break;
                        case 'answered':
                        default:
                            if (data.from._id === User._id)
                                output = 'Outgoing Call'
                            else
                                output = 'Incoming Call'
                            break;
                    }
                    setText((includeFrom ? data.from.firstname + ': ' : '') + output)
                }
            }
        }
    }, [data.message])

    return <span dangerouslySetInnerHTML={{ __html: text }}></span>
}

export default memo(MessageText)